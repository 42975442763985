@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.edit-session {
  $breakpoint: 600px;
  padding-bottom: 200px;

  &__header {
    background-color: light.$background-secondary-default;
    padding: spacing.$m 0;
    margin-bottom: spacing.$m;

    @media (min-width: $breakpoint) {
      padding: spacing.$xl 0;
      margin-bottom: spacing.$xl;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
    background-color: light.$background-secondary-default;
    padding: spacing.$m;
    border-radius: corner-radius.$s;
    margin-bottom: spacing.$m;

    @media (min-width: $breakpoint) {
      margin-bottom: spacing.$xl;
    }
  }

  &__when {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;

    @media (min-width: $breakpoint) {
      max-width: 300px;
    }
  }

  &__time {
    display: flex;
    gap: spacing.$xs;
  }

  &__time-divider {
    position: absolute;
    bottom: 14px; // NOTE: Magical number to ensure the divider is centered visually
    width: 100%;
  }

  &__time-divider-wrapper {
    position: relative;
    width: 13px; // NOTE: Magical number to make the divider look good
  }

  &__cancel {
    padding: spacing.$xxs 0;
    background-color: light.$background-secondary-default;
    border-radius: corner-radius.$s;
  }

  &__cancel-item {
    margin: 0 spacing.$s;
    padding: spacing.$xs 0;
    &:not(:last-child) {
      border-bottom: 1px solid light.$ge-divider-default;
    }
  }

  &__links {
    display: flex;
    gap: spacing.$xs;

    margin-top: spacing.$m;
  }
}
